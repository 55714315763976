import { batch } from "react-redux";
import moment from "moment";
import { setTravelerAttrEnabled } from "@app/analysis/addOns/state/addOns.actions";
import {
    getBasics,
    getCalibrationSettings,
    getIsUSTruckVolumeAnalysis,
} from "@app/analysis/basics/state/basics.selectors";
import {
    CALIBRATIONS,
    CREATE_ANALYSIS_TYPES,
    TCalibration,
} from "@app/analysis/state/analysisConfiguration.constants";
import {
    collapseMonthsToRanges,
    getIsCVDAnalysisWithTravelerAttrsSupport,
} from "@app/analysis/state/analysisConfiguration.helpers";
import {
    getAccessibleDataPeriodsForCountryAndHistoricalYears,
    getDefaultDataPeriodsForMode,
} from "@app/analysis/state/analysisConfiguration.selectors";
import {
    getAnalysisTypeCode,
    getCensusMixingPermitted,
    getGeneral,
} from "@app/analysis/state/general/general.selectors";
import { setDataPeriod } from "@app/analysis/timePeriods/components/dataPeriodParams/dataPeriodTable/dataPeriodRow.actions";
import {
    setDataMonths,
    setTimePeriodsValidation,
} from "@app/analysis/timePeriods/state/timePeriods.actions";
import { TIME_PERIODS_ACTION_TYPES } from "@app/analysis/timePeriods/state/timePeriods.actionTypes";
import {
    getHasTimePeriodWithCVDTravelerAttrsSupport,
    getIsDataPeriodsMixedWithCensus2020,
    getIsOnlyTruckVolumeDataPeriods,
} from "@app/analysis/timePeriods/state/timePeriods.helpers";
import {
    getDataPeriodSettings,
    getTimePeriods,
} from "@app/analysis/timePeriods/state/timePeriods.selectors";
import type { IDateRange } from "@app/analysis/timePeriods/timePeriods.types";
import { createValidateField } from "@app/analysis/validation";
import type { TAppDispatch } from "@app/store";
import type { TGetState } from "@app/store/root.reducer";
import {
    ANALYSIS_TYPES,
    MODES_OF_TRAVEL,
    TAnalysisType,
} from "@common/constants/analysis.constants";
import { getIsNetworkPerformanceAnalysis } from "@common/helpers/analysis";
import type { IAvailableDataPeriodAPI } from "@common/services/server/analysesApi.types";
import { getDataPeriodYearDefaults } from "./dataPeriodParams.helpers";

// Creates the validation field for data periods.
export const validateDataPeriodsField = ({
    dataPeriods,
    dataPeriodType = "dataPeriods",
    censusMixingPermitted = true,
    isTruckVolumeAnalysis,
}: {
    dataPeriodType?: string;
    dataPeriods: Array<IDateRange>;
    censusMixingPermitted?: boolean;
    isTruckVolumeAnalysis?: boolean;
}) => {
    if (dataPeriodType === "dataPeriods") {
        // At least one data period
        if (!dataPeriods.length) {
            return createValidateField(
                "No data periods are selected. Analysis must have at least one data period",
            );
        }
        if (!censusMixingPermitted) {
            const isMixedWithCensus2020 = getIsDataPeriodsMixedWithCensus2020({
                dataPeriodsData: dataPeriods,
                dataPeriodsType: "dataPeriods",
            });
            if (isMixedWithCensus2020) {
                return createValidateField(
                    "Selected data periods combination is not supported. Please make sure all data periods end before Jan 2019 or begin Jan 2019 or later",
                );
            }
        }
        if (isTruckVolumeAnalysis) {
            const isTruckVolumeDataPeriods = getIsOnlyTruckVolumeDataPeriods({
                dataPeriodsData: dataPeriods,
                dataPeriodsType: "dataPeriods",
            });
            if (!isTruckVolumeDataPeriods) {
                return createValidateField(
                    "Selected data periods combination is not supported for StreetLight Truck Volume. Please make sure all data periods end before Jan 2022 and begin after Dec 2018",
                );
            }
        }
    }

    if (dataPeriodType === "excludedDataPeriods") {
        if (!dataPeriods.length) {
            return createValidateField(
                "No excluded data periods are selected. Either disable them or select at least one excluded data period",
            );
        }

        const invalidDates = dataPeriods.some(period => !period.startDate || !period.endDate);

        if (invalidDates) {
            return createValidateField(
                "Invalid excluded date ranges. Please correct excluded date ranges",
            );
        }
    }

    return null;
};

export const addDataPeriodWithDefaultPeriods =
    (dataPeriodType: string, defaultDataPeriods: Array<IDateRange>) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const dataPeriodSettings = getDataPeriodSettings(state);
        const censusMixingPermitted = getCensusMixingPermitted(state);
        const isTruckVolumeAnalysis = getIsUSTruckVolumeAnalysis(state) as boolean;

        const lastIdx = dataPeriodSettings.dataPeriods
            ? dataPeriodSettings.dataPeriods.length - 1
            : -1;
        const lastDataPeriod = lastIdx >= 0 ? dataPeriodSettings.dataPeriods[lastIdx] : undefined;
        const lastEndDate = lastDataPeriod ? lastDataPeriod.endDate : undefined;
        const numDefaultPeriods = defaultDataPeriods.length;

        const lastDataDate = numDefaultPeriods
            ? new Date(defaultDataPeriods[numDefaultPeriods - 1].endDate)
            : undefined;

        const openToDate = lastEndDate
            ? moment(lastEndDate).add(1, "days").valueOf()
            : lastDataDate;

        const newDataPeriod = {
            openToDate: openToDate,
            startDate: null,
            endDate: null,
            status: "adding",
        };

        const dataPeriods = [...(dataPeriodSettings[dataPeriodType] || []), newDataPeriod];

        const newValidation = {
            [dataPeriodType]: validateDataPeriodsField({
                dataPeriods,
                dataPeriodType,
                censusMixingPermitted,
                isTruckVolumeAnalysis,
            }),
        };

        batch(() => {
            dispatch(setDataPeriod(dataPeriodType, dataPeriods));
            dispatch(setTimePeriodsValidation(newValidation));
        });
    };

export const addDataPeriod =
    (dataPeriodType: string) => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const dataPeriods = getDefaultDataPeriodsForMode(state);

        dispatch(addDataPeriodWithDefaultPeriods(dataPeriodType, dataPeriods));
    };

export const setDataPeriods =
    (dataPeriods: Array<IDateRange>) => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();
        const censusMixingPermitted = getCensusMixingPermitted(state);
        const isTruckVolumeAnalysis = getIsUSTruckVolumeAnalysis(state);
        const analysisTypeCode = getAnalysisTypeCode(state);
        const { travelModeCode } = getBasics(state);
        const { shouldUseCustomRanges } = getTimePeriods(state);

        const isKFactorAnalysis =
            analysisTypeCode === CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.code;
        const hasTravelerAttrsSupported = getHasTimePeriodWithCVDTravelerAttrsSupport({
            dataMonths: null,
            dataPeriods,
            analysisTypeCode,
            travelModeCode,
        });
        const newValidation = {
            dataPeriods:
                !isKFactorAnalysis && !shouldUseCustomRanges
                    ? validateDataPeriodsField({
                          dataPeriods,
                          censusMixingPermitted,
                          isTruckVolumeAnalysis,
                      })
                    : null,
        };

        batch(() => {
            dispatch({
                type: TIME_PERIODS_ACTION_TYPES.SET_DATA_PERIODS,
                data: { dataPeriods },
            });
            dispatch(setTimePeriodsValidation(newValidation));

            if (!hasTravelerAttrsSupported) {
                dispatch(setTravelerAttrEnabled(false));
            }
        });
    };

export const setDataPeriodForYear = (year: number) => (dispatch: TAppDispatch) => {
    const newDataPeriods = getDataPeriodYearDefaults(year);

    dispatch(setDataPeriods(newDataPeriods));
};

export const setToRecommendedDataPeriods = () => (dispatch: TAppDispatch, getState: TGetState) => {
    const state = getState();

    const dataPeriods = getDefaultDataPeriodsForMode(state);

    dispatch(setDataPeriods(dataPeriods));
};

export const clearDataPeriods = () => (dispatch: TAppDispatch) => {
    const newValidation = {
        dataPeriods: validateDataPeriodsField({ dataPeriods: [] }),
    };

    batch(() => {
        dispatch({
            type: TIME_PERIODS_ACTION_TYPES.CLEAR_DATA_PERIODS,
        });
        dispatch(setTimePeriodsValidation(newValidation));
    });
};

export const toggleExcludeDateRanges = () => (dispatch: TAppDispatch) => {
    batch(() => {
        dispatch({ type: TIME_PERIODS_ACTION_TYPES.TOGGLE_EXCLUDE_DATE_RANGES });
        dispatch(setTimePeriodsValidation({ excludedDataPeriods: null }));
    });
};

export const setDefaultTruckDataPeriodsByCommercialVehicleType =
    (cvMedium: boolean, analysisTypeCode: TAnalysisType["id"]) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const accessibleDataPeriods = getAccessibleDataPeriodsForCountryAndHistoricalYears(state);
        const { calibrationCode } = getCalibrationSettings(state);

        const filterFlagForCalibration =
            calibrationCode === CALIBRATIONS.TRIP_COUNTS_TRUCK_GT.code
                ? "is_default_geotab"
                : "is_default_geotab_volume";
        const filterFlagForCommercialVehicleType = cvMedium
            ? "is_default_gps"
            : "is_default_gps_heavy";

        const defaultDataMonths = accessibleDataPeriods.filter(
            (dataPeriod: IAvailableDataPeriodAPI) => {
                if (
                    analysisTypeCode !== ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.id &&
                    analysisTypeCode !== ANALYSIS_TYPES.NETWORK_PERFORMANCE_TRUCK_SEGMENT.id
                )
                    return dataPeriod[filterFlagForCommercialVehicleType];

                return (
                    dataPeriod[filterFlagForCalibration] &&
                    dataPeriod[filterFlagForCommercialVehicleType]
                );
            },
        );

        batch(() => {
            dispatch(setDataMonths(defaultDataMonths));
            dispatch(setDataPeriods(collapseMonthsToRanges(defaultDataMonths, [])));
        });
    };

export const setDefaultTruckDataPeriodsByCalibrationCode =
    (calibrationCode?: TCalibration["code"]) => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();
        const { analysisTypeCode } = getGeneral(state);
        const { travelModeCode } = getBasics(state);

        if (
            getIsNetworkPerformanceAnalysis(analysisTypeCode) &&
            travelModeCode === MODES_OF_TRAVEL.TRUCK_GT.code
        ) {
            const accessibleDataPeriods =
                getAccessibleDataPeriodsForCountryAndHistoricalYears(state);
            const filterExpression =
                calibrationCode === CALIBRATIONS.TRIP_COUNTS_TRUCK_GT.code
                    ? (dataPeriod: IAvailableDataPeriodAPI) => dataPeriod.is_default_geotab
                    : (dataPeriod: IAvailableDataPeriodAPI) => dataPeriod.is_default_geotab_volume;
            const defaultDataMonths = accessibleDataPeriods.filter(filterExpression);

            batch(() => {
                dispatch(setDataMonths(defaultDataMonths));
                dispatch(setDataPeriods(collapseMonthsToRanges(defaultDataMonths, [])));
            });
        }
    };
